import moment from "moment";
import React, { useState } from "react";
import "./Missing.css";
import Heading from "../../components/Heading/Heading";
import CaseModal from "../../components/CaseModal/CaseModal";
import FilteredTable from "../../components/FilteredTable/FilteredTable";
import SearchResults from "../../components/SearchResults/SearchResults";

function Missing(props) {
  /* Displays list of Events with Missing DICOMS and/or Biometrics */
  //get list of all physicians from api - remove dups
  const physicianList = function () {
    let arr = [];
    props.missingItems &&
      props.missingItems.length > 0 &&
      props.missingItems.map((m) => {
        if (!arr.includes(m.physicians[0].name)) {
          arr.push(m.physicians[0].name);
        }
        return arr;
      });
    return arr.sort();
  };

  //physicians filter dropdown state

  const [phyFilter, setPhyFilter] = useState("");

  //physicians filter dropdown
  const filterDropdown = function () {
    let list = physicianList();

    let array = [
      {
        value: "",
        option: "",
      },
    ];

    for (var i = 0; i < list.length; i++) {
      let obj = {};
      obj.value = list[i];
      obj.option = list[i];
      array.push(obj);
    }
    return array;
  };

  const [searchValue, setSearchValue] = useState("");

  //missing item type filter dropdown
  const [missingFilter, setMissingFilter] = useState("");
  const missingDropdown = [
    {
      value: "",
      option: "",
    },
    {
      value: "dicom",
      option: "DICOM",
    },
    {
      value: "template",
      option: "Template",
    },
    {
      value: "ht-wt",
      option: "Height/Weight",
    },
    {
      value: "case-details",
      option: "Case Details",
    },
  ];

  return (
    <div className="mt-4 Missing">
      <Heading
        currentPath={props.location.pathname}
        filterDropdown={filterDropdown}
        phyFilter={phyFilter}
        setPhyFilter={setPhyFilter}
        missingItems={props.missingItems}
        missingDropdown={missingDropdown}
        missingFilter={missingFilter}
        setMissingFilter={setMissingFilter}
        isLoadedQuery={props.isLoadedQuery}
        setIsLoadedQuery={props.setIsLoadedQuery}
        setQuery={props.setQuery}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {searchValue !== "" && props.isLoadedQuery ? (
        <SearchResults
          location={props.location}
          events={props.queryEvents}
          phyFilter={phyFilter}
          locFilter={""}
          pushCase={props.pushCase}
          isPushCase={props.isPushCase}
        />
      ) : (
        <div className="container-fluid table-responsive">
          <table className="table table-md table-bordered mt-4">
            <thead className="Missing-thead">
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Physician</th>
                <th>Case ID</th>
                <th>Patient</th>
                <th>
                  <div className="row justify-content-center align-items-start">
                    <div className="col col-md-3">Dicoms</div>
                    <div className="col col-md-3">Templated Img</div>
                    <div className="col col-md-3">Height/Weight</div>
                    <div className="col col-md-3">Case Details</div>
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {phyFilter || missingFilter ? (
                <FilteredTable
                  selectedWeek={[]}
                  phyFilter={phyFilter}
                  missingEvents={props.missingItems}
                  location={props.location}
                  pushCase={props.pushCase}
                  missingFilter={missingFilter}
                />
              ) : (
                props.missingItems &&
                props.missingItems.length > 0 &&
                props.missingItems.map((m, idx) => (
                  <tr className="Missing-list" key={`missing-item-${idx}`}>
                    <td
                      width="2%"
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      {idx + 1}
                    </td>
                    <td
                      width="10%"
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      {moment(m.start).format("MM/DD/YY")}
                    </td>
                    <td
                      width="10%"
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      {m.physicians.map((p) => p.name)}
                    </td>
                    <td
                      width="10%"
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      {m.event_id}
                    </td>
                    <td
                      width="5%"
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      {m.patient.initials.first + m.patient.initials.last}
                    </td>
                    <td
                      width="40%"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target={`#m${m.event_id}`}
                    >
                      <div className="row justify-content-center align-items-start">
                        <div className="col col-md-3">
                          {m.study.length !== 0 ? (
                            <img
                              width="20px"
                              src="/images/DICOM.svg"
                              alt="dicom icon"
                            />
                          ) : (
                            <img
                              width="20px"
                              src="/images/DICOM-missing.svg"
                              alt="dicom-missing icon"
                            />
                          )}
                        </div>
                        <div className="col col-md-3">
                          <i
                            className="far fa-file-pdf"
                            style={{ color: m.templated ? "black" : "red" }}
                          ></i>
                        </div>
                        <div className="col col-md-3">
                          <i
                            className="fas fa-ruler-vertical"
                            style={{
                              color:
                                m.demographics.height || m.demographics.weight
                                  ? "black"
                                  : "red",
                            }}
                          ></i>
                        </div>
                        <div className="col col-md-3">
                          <i
                            className="far fa-clipboard"
                            style={{
                              color:
                                m.location.name &&
                                m.patient.initials.first !== "" &&
                                m.patient.initials.last !== "" &&
                                m.patient.mrn &&
                                m.demographics.gender !== "Unknown" &&
                                m.demographics.age !== 0
                                  ? "black"
                                  : "red",
                            }}
                          ></i>
                        </div>
                      </div>
                    </td>
                    <td width="5%" onClick={() => props.pushCase(m.event_id)}>
                      <i className={`fas fa-sync-alt `} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {props.missingItems &&
            props.missingItems.length > 0 &&
            props.missingItems.map((event) => (
              <CaseModal
                event={event}
                key={`missing-item-${event.event_id}`}
                pushCase={props.pushCase}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Missing;
