import React from "react";
import moment from "moment";
import "./CaseModal.css";

function CaseModal(props) {
  let event = props.event;

  return (
    <div
      key={`case-modal-${event.event_id}`}
      className="modal fade"
      id={`m${event.event_id}`}
      tabIndex="-1"
    >
      <div className="modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="case-modal">
              Case #{event.event_id}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-start">
            <div className="box">
              <h5>Case Details</h5>
              <div
                className="row"
                style={{ color: event.case ? "black" : "red" }}
              >
                <div className="col-2">
                  <b>Case Name:</b>
                </div>
                <div className="col-6">
                  {event.case !== "Undefined" ? event.case : ""}
                </div>
              </div>
              <div
                className="row"
                style={{
                  color: event.procedure !== "Undefined" ? "black" : "red",
                }}
              >
                <div className="col-2">
                  <b>Procedure:</b>
                </div>
                <div className="col-6">
                  {event.procedure !== "Undefined" ? event.procedure : ""}
                </div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.location && event.location.name ? "black" : "red",
                }}
              >
                <div className="col-2">
                  <b>Location:</b>
                </div>
                <div className="col-6">{event.location.name}</div>
              </div>
              <div
                className="row"
                style={{ color: event.start && event.end ? "black" : "red" }}
              >
                <div className="col-2">
                  <b>Time:</b>
                </div>
                <div className="col-6">
                  {moment(event.start).format("dddd, MM-DD-YYYY. hh:mm A")} --{" "}
                  {moment(event.end).format("hh:mm A")}
                </div>
              </div>
              <div
                className="row"
                style={{ color: event.orientation ? "black" : "red" }}
              >
                <div className="col-2">
                  <b>Orientation:</b>
                </div>
                <div className="col-6">{event.orientation}</div>
              </div>
              <div
                className="row"
                style={{ color: event.physicians.length > 0 ? "black" : "red" }}
              >
                <div className="col-2">
                  <b>Physician(s):</b>
                </div>
                <div className="col-6">
                  {event.physicians.length > 0 &&
                    event.physicians.map((p) => (
                      <div key={p.npi}>
                        {p.name}, NPI:{p.npi}
                      </div>
                    ))}
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <b>Reps:</b>
                </div>
                <div className="col-6">
                  {event.reps.length > 0 &&
                    event.reps.map((r) => <div key={r.id}>{r.name}</div>)}
                </div>
              </div>
            </div>

            <div className="box">
              <h5>Patient Details</h5>
              <div
                className="row"
                style={{
                  color:
                    event.patient && event.patient.name !== ""
                      ? "black"
                      : "red",
                }}
              >
                <div className="col-2">
                  <b>Name:</b>
                </div>
                <div className="col-6">
                  {event.patient.name.first + " " + event.patient.name.last}
                </div>
              </div>
              <div
                className="row"
                style={{
                  color: event.patient && event.patient.mrn ? "black" : "red",
                }}
              >
                <div className="col-2">
                  <b>MRN:</b>
                </div>
                <div className="col-6">{event.patient.mrn}</div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.demographics && event.demographics.age
                      ? "black"
                      : "red",
                }}
              >
                <div className="col-2">
                  <b>Age:</b>
                </div>
                <div className="col-6">{event.demographics.age}</div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.demographics &&
                    event.demographics.gender !== "Unknown"
                      ? "black"
                      : "red",
                }}
              >
                <div className="col-2">
                  <b>Gender:</b>
                </div>
                <div className="col-6">
                  {event.demographics.gender !== "Unknown"
                    ? event.demographics.gender
                    : ""}
                </div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.demographics && event.demographics.height
                      ? "black"
                      : "red",
                }}
              >
                <div className="col-2">
                  <b>Height:</b>
                </div>
                <div className="col-6">{event.demographics.height}</div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.demographics && event.demographics.weight
                      ? "black"
                      : "red",
                }}
              >
                <div className="col-2">
                  <b>Weight:</b>
                </div>
                <div className="col-6">{event.demographics.weight}</div>
              </div>
              <div
                className="row"
                style={{
                  color:
                    event.study && event.study.length > 0 ? "black" : "red",
                }}
              >
                <div className="col-2">
                  <b>Dicoms:</b>
                </div>
                <div className="col-6">
                  {event.study.length > 0
                    ? event.study.map((s) => (
                        <a href={s.url} key={s.id}>
                          {s.name}
                        </a>
                      ))
                    : ""}
                </div>
              </div>
              <div
                className="row"
                style={{
                  color: event.templated && event.templated ? "black" : "red",
                }}
              >
                <div className="col-2">
                  <b>Templated-Img:</b>
                </div>
                <div className="col-6">
                  {event.templated ? "Templated-Img" : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <b>Planned Parts:</b>
                </div>
                <div className="col-6">
                  {event.equipment.planned !== null
                    ? event.equipment.planned.map((a, idx) => (
                        <li key={a.number + idx}>{a.name}</li>
                      ))
                    : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <b>Actual Parts:</b>
                </div>
                <div className="col-6">
                  {event.equipment.actual !== null
                    ? event.equipment.actual.map((a, idx) => (
                        <li key={a.number + idx}>{a.name}</li>
                      ))
                    : ""}
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <b>Devices:</b>
                </div>
                <div className="col-6">
                  {event.equipment.devices !== null
                    ? event.equipment.devices.map((a, idx) => (
                        <li key={a.number + idx}>{a.name}</li>
                      ))
                    : ""}
                </div>
              </div>
            </div>
            <div className="box">
              <h5>Images in Pacs</h5>
              <table className="table table-md table-bordered mt-3">
                <thead className="Images-thead">
                  <tr>
                    <th>Study Title</th>
                    <th>Modality</th>
                    <th>Thin Study</th>
                    <th>Study Date</th>
                    <th>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {event.studies_in_pacs.length > 0 &&
                    event.studies_in_pacs.map((study, idx) => (
                      <tr key={study + idx}>
                        <td>{study.name ? study.name : ""}</td>
                        <td>{study.modality ? study.modality : ""}</td>
                        <td>{study.thin ? "Yes" : "No"}</td>
                        <td>
                          {study.date_taken
                            ? moment(study.date_taken).format("MM-DD-YYYY")
                            : ""}
                        </td>
                        <td>
                          {study.date_uploaded
                            ? moment(study.date_uploaded).format("MM-DD-YYYY")
                            : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => props.pushCase(event.event_id)}
            >
              Resend Case
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseModal;
