export const getUserPreferencesURI = "/json/preferences/get";
export const setUserPreferencesURI = "/json/preferences/set";
export const getSharedCalendarPreferencesURI =
  "/json/preferences/get-shared-calendar-preferences";
export const billingCodesSearchURI = "/json/calendar/billing/codes";
export const calendarUpdateChecklistURI = "/json/calendar/update/checklist";
export const getSharedSchedulesURI = "/json/calendar/share/others";
export const scheduleSearchURI = "/json/calendar/search";
export const calendarCreateURI = "/json/calendar/create";
export const calendarEditURI = "/json/calendar/edit";
export const calendarDeleteURI = "/json/calendar/delete";
export const contactUsURI = "/json/contact-us";
export const getCalendarSubscriptionURI = "/json/calendar/ical";
export const profileURI = "/json/profile";
export const profileIndustryURI = "/json/profile/industry";
export const agendaURI = "/json/calendar/outlook/agenda";
export const draftsURI = "/json/calendar/outlook/drafts";
export const viewCaseURI = "/json/calendar/view";
export const calendarMonthURI = "/json/calendar/outlook/month2";
export const contactsURI = "/json/contacts/list";
export const editorsURI = "/json/calendar/share/my-calendar/editors";
export const addEditorURI = "/json/calendar/share/my-calendar/editors/add";
export const removeEditorURI =
  "/json/calendar/share/my-calendar/editors/remove";
export const loginStatusURI = "/json/login/status";
export const getSubGroupsURI =
  "/json/directory/practice/all-members/sub-groups";
export const getSuggestedTimesURI = "/json/calendar/suggested-times";
export const jwtTokenURI = "/json/jwt/token";
export const attendeeSearchURI = "/json/attendee/search";
// Practice Dashboard
export const allCasesURI = "/json/dashboard/practice/cases";
export const editNotesURI = "/json/calendar/edit/notes";
export const settingsURI = "/json/dashboard/practice/settings";
export const setRightsURI = "/json/dashboard/practice/settings/rights";
export const getRebookDataURI = "/json/dashboard/practice/rebook/patient-list";
export const changeTimesURI = "/json/calendar/change-times";
export const pendingCasesURI = "/json/live-calendar/pending";
export const physiciansScheduleURI =
  "/json/dashboard/practice/physician_schedule";
export const editEventsURI = "/json/dashboard/practice/edit_event";
export const getAvailabilityTimesURI = "/json/availability/get";
export const setAvailabilityTimesURI = "/json/availability/set";
export const getAvailabilityCalendarURI = "/json/availability/calendar/get";
export const setAvailabilityCalendarURI = "/json/availability/calendar/set";
export const getDSLicensesURI =
  "/json/docspera-administrator/users/{userid}/permissions/modules";
export const getPracticePermissionsURI =
  "/json/docspera-administrator/users/{userid}/practices";
export const uploadFileURI = "/json/cabinet/upload";
export const updateFilePHIURI = "/json/cabinet/phi";
export const sendTelehealthInviteURI = "/json/calendar/{eventid}/telehealth";
export const sendPatientIntakeURI = "/json/calendar/{eventid}/survey";
export const getClashesURI = "/json/calendar/clash";
export const getCountsURI = "/json/counts";
export const getChecklistsURI = "/json/calendar/checklist";
