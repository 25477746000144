import axios from "axios";
import { getCookie } from "../libraries/helpers";

const instance = axios.create({
  baseURL: "/",
  withCredentials: true, // todo: make this configurable
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${getCookie("docsperajwt")}`;
  return request;
};

instance.interceptors.request.use((request) => requestHandler(request));

async function getConfigApi() {
  const url = "/config.json";
  return fetch(url, { method: "GET" }).then((response) => {
    if (response.data) {
      return response.data;
    } else {
      throw new Error("no config data");
    }
  });
}

async function getEventSchedulingOverview() {
  const url = "/json/event-scheduling?overview=1";

  return instance
    .get(url)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function getQueryEvents(url) {
  return instance
    .get(url)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

async function putEvent(url) {
  return instance
    .put(url)
    .then((response) => {
      // process the response
      return new Promise((resolve, reject) => {
        if (response.data) {
          let json = response.data;
          resolve(json);
        } else {
          reject();
        }
      });
    })
    .catch(() => {
      console.log("error"); //.warn .write
    });
}

export { getConfigApi, getEventSchedulingOverview, getQueryEvents, putEvent };
