import React from 'react'
import './Heading.css'
import { Dropdown } from 'docspera-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const Heading = (props) => {
  const handleViewToggle = () => {
    props.setViewToggle(!props.viewToggle);
  };

  const handleQuery = (e) => {
    props.setQuery([]);
    props.setSearchValue(e.target.value);
  };

  const handleQuery2 = (e) => {
    props.setSearchValue(e.target.value);
  };

  //handle search submit
  const handleSubmit = () => {
    if (
      (props.searchValue !== "" || (props.startDate && props.endDate)) &&
      props.searchType !== "physician"
    ) {
      console.log(props.searchValue);
      console.log(props.startDate);
      console.log(props.endDate);
      let value =
        props.searchType === "date"
          ? `${moment(props.startDate).format("YYYYMMDD")}-${moment(
              props.endDate
            ).format("YYYYMMDD")}`
          : props.searchValue;
      props.getQuery(value, props.searchType);
    } else if (props.searchType === "physician") {
      let physician = props.searchValue.replace(/\s+/g, "").toLowerCase();
      let data = props.allEvents.filter((event) =>
        event.physicians[0].name.toLowerCase().includes(physician)
      );
      props.setIsLoadedQuery(true);
      props.setQuery(data);
    }
  };

  const handleSubmit2 = () => {
    let foundEvent = props.missingItems.filter(
      (event) => event.event_id === parseInt(props.searchValue)
    );
    console.log(foundEvent);
    props.setIsLoadedQuery(true);
    props.setQuery(foundEvent);
  };

  //clear all search and filters
  const clearAll = () => {
    props.setSearchValue("");
    props.setLocFilter("");
    props.setPhyFilter("");
    props.setDateRange("");
  };

  let heading =
    props.currentPath === "/" ? (
      <div className="alignment">
        {(props.searchValue || (props.startDate && props.endDate)) &&
        props.isLoadedQuery ? (
          <h2>
            Search Results:{" "}
            <span className="Heading-text">
              {props.searchType === "date"
                ? `${moment(props.startDate).format("MM/DD/YY")}-${moment(
                    props.endDate
                  ).format("MM/DD/YY")}`
                : props.searchValue}
            </span>
          </h2>
        ) : (
          <h2>
            Week of{" "}
            <span className="Heading-text">
              {props.getCurrentWeekDate.monday} -{" "}
              {props.getCurrentWeekDate.friday}
            </span>
          </h2>
        )}

        <div className="item-align">
          <div className="me-3">
            <p className="Heading-guide">View</p>
            <button
              type="button"
              className={`btn btn-outline-secondary ${
                props.viewToggle ? "fas fa-hospital" : "fas fa-user-md"
              } Heading-toggle`}
              onClick={handleViewToggle}
            />
          </div>

          <div style={{ width: "100px" }}>
            <p className="Heading-guide">Search</p>
            <Dropdown
              options={props.searchDropdown}
              value={props.searchType}
              setValue={props.setSearchType}
            />
          </div>
          <div className="Heading-input ms-1">
            {props.searchType === "date" ? (
              <DatePicker
                className="form-control Heading-datepicker"
                selectsRange={true}
                startDate={props.startDate}
                endDate={props.endDate}
                isClearable={true}
                onChange={(update) => {
                  props.setDateRange(update);
                }}
                placeholderText="Search by date range"
              />
            ) : (
              <input
                type="text"
                className="form-control"
                placeholder={`Search by ${props.searchType}`}
                value={props.searchValue}
                onChange={handleQuery}
                onKeyPress={handleSubmit}
              />
            )}
          </div>

          <div className="ms-1 me-3 Heading-input">
            <button
              type="button"
              className="btn fas fa-search Heading-search"
              onClick={handleSubmit}
            />
          </div>

          <div style={{ width: "180px" }}>
            <p className="Heading-guide">Physician</p>
            <Dropdown
              options={props.filterDropdown(1)}
              value={props.phyFilter}
              setValue={props.setPhyFilter}
            />
          </div>
          <div style={{ width: "180px" }} className="ms-1">
            <p className="Heading-guide">Location</p>
            <Dropdown
              options={props.filterDropdown(0)}
              value={props.locFilter}
              setValue={props.setLocFilter}
            />
          </div>
          <div className="ms-1 me-3 Heading-clear">
            <button
              type="button"
              className={`btn btn-outline-secondary`}
              onClick={clearAll}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
    ) : props.currentPath === "/status" ? (
      <div className="alignment">
        <h2>Integration Status</h2>
      </div>
    ) : props.currentPath === "/locnpro" ? (
      <div className="alignment">
        <h2>Locations &amp; Providers</h2>
      </div>
    ) : (
      <div className="alignment">
        <h2>Missing DICOMS &amp; Biometrics</h2>
        <div className="item-align">
          <div style={{ width: "180px" }}>
            <p className="Heading-guide">Missing Item</p>
            <Dropdown
              options={props.missingDropdown}
              value={props.missingFilter}
              setValue={props.setMissingFilter}
            />
          </div>

          <div className="Heading-input ms-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by event id"
              value={props.searchValue}
              onChange={handleQuery2}
              onKeyPress={handleSubmit2}
            />
          </div>
          <div className="ms-1 me-3 Heading-input">
            <button
              type="button"
              className="btn fas fa-search Heading-search"
              onClick={handleSubmit2}
            />
          </div>

          <div style={{ width: "180px" }}>
            <p className="Heading-guide">Physician</p>
            <Dropdown
              options={props.filterDropdown(1)}
              value={props.phyFilter}
              setValue={props.setPhyFilter}
            />
          </div>
        </div>
      </div>
    );

  return heading;
};

export default Heading;
