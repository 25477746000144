import React from "react";
import "./Info.css";
import Heading from "../../components/Heading/Heading";

function Info(props) {
  return (
    <div className="mt-4">
      <Heading currentPath={props.location.pathname} />
      <div className="container-fluid table-responsive">
        <table className="table table-hover table-sm table-bordered">
          <thead>
            <tr>
              <th>Doctor</th>
              <th>Affiliated Hospitals</th>
              <th>Mapping</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Doc</td>
              <td>
                <ul className="Info-list">
                  <li>hospital 1</li>
                  <li>hospital 2</li>
                  <li>hospital 3</li>
                </ul>
              </td>
              <td>Mapping</td>
            </tr>
            <tr>
              <td>Doc</td>
              <td>
                <ul className="Info-list">
                  <li>hospital 1</li>
                  <li>hospital 2</li>
                  <li>hospital 3</li>
                </ul>
              </td>
              <td>Mapping</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Info;
