import React from "react";
import moment from "moment";
import "./SearchResults.css";
import CaseModal from "../CaseModal/CaseModal";

function SearchResults(props) {
  //filtered list for location and/or physicians for search results
  let filteredEvents = () => {
    switch (props.phyFilter !== "" || props.locFilter !== "") {
      case props.phyFilter !== "" && props.locFilter !== "":
        return props.events.filter(
          (event) =>
            event.physicians[0].name === props.phyFilter &&
            event.location.name === props.locFilter
        );
      case props.phyFilter !== "" && props.locFilter === "":
        return props.events.filter(
          (event) => event.physicians[0].name === props.phyFilter
        );
      case props.phyFilter === "" && props.locFilter !== "":
        return props.events.filter(
          (event) => event.location.name === props.locFilter
        );
      default:
        break;
    }
  };

  //display search results w/o filters
  let row = props.events.map((e, idx) => (
    <tr className="Calendar-list" key={`${e.event_id}${idx}`}>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {moment(e.start).format("MM/DD/YY")}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {moment(e.start).format("h:mma")}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.event_id}
      </td>
      <td width="12%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.physicians[0].name}
      </td>
      <td width="16%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.location.name}
      </td>
      <td data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        <div className="row">
          <div className="col col-sm-2">
            {e.procedure !== "Undefined" ? e.procedure : "N/A"}
          </div>
          <div className="col col-sm-2">
            {e.patient.initials.first + e.patient.initials.last}
          </div>
          <div className="col col-sm-2">
            {e.study.length !== 0 ? (
              <img width="14px" src="/images/DICOM.svg" alt="dicom icon" />
            ) : (
              <img
                width="14px"
                src="/images/DICOM-missing.svg"
                alt="dicom-missing icon"
              />
            )}
          </div>
          <div className="col col-sm-2">
            <i
              className="far fa-file-pdf"
              style={{ color: e.templated ? "black" : "red" }}
            ></i>
          </div>
          <div className="col col-sm-2">
            <i
              className="fas fa-ruler-vertical"
              style={{
                color:
                  e.demographics.height || e.demographics.weight
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
          <div className="col col-sm-2">
            <i
              className="far fa-clipboard"
              style={{
                color:
                  e.location.name &&
                  e.patient.initials.first !== "" &&
                  e.patient.initials.last !== "" &&
                  e.patient.mrn &&
                  e.demographics.gender !== "Unknown" &&
                  e.demographics.age !== 0
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
        </div>
      </td>
      <td onClick={() => props.pushCase(e.event_id)}>
        <i className={`fas fa-sync-alt `}></i>
      </td>
    </tr>
  ));

  //display search results w/ filters
  let filteredRow = filteredEvents().map((e, idx) => (
    <tr className="Calendar-list" key={`${e.event_id}${idx}`}>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {moment(e.start).format("MM/DD/YY")}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {moment(e.start).format("h:mma")}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.event_id}
      </td>
      <td width="12%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.physicians[0].name}
      </td>
      <td width="16%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.location.name}
      </td>
      <td data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        <div className="row">
          <div className="col col-sm-2">
            {e.procedure !== "Undefined" ? e.procedure : "N/A"}
          </div>
          <div className="col col-sm-2">
            {e.patient.initials.first + e.patient.initials.last}
          </div>
          <div className="col col-sm-2">
            {e.study.length !== 0 ? (
              <img width="14px" src="/images/DICOM.svg" alt="dicom icon" />
            ) : (
              <img
                width="14px"
                src="/images/DICOM-missing.svg"
                alt="dicom-missing icon"
              />
            )}
          </div>
          <div className="col col-sm-2">
            <i
              className="far fa-file-pdf"
              style={{ color: e.templated ? "black" : "red" }}
            ></i>
          </div>
          <div className="col col-sm-2">
            <i
              className="fas fa-ruler-vertical"
              style={{
                color:
                  e.demographics.height || e.demographics.weight
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
          <div className="col col-sm-2">
            <i
              className="far fa-clipboard"
              style={{
                color:
                  e.location.name &&
                  e.patient.initials.first !== "" &&
                  e.patient.initials.last !== "" &&
                  e.patient.mrn &&
                  e.demographics.gender !== "Unknown" &&
                  e.demographics.age !== 0
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
        </div>
      </td>
      <td onClick={() => props.pushCase(e.event_id)}>
        <i className={`fas fa-sync-alt `}></i>
      </td>
    </tr>
  ));

  //display search result from missing items w/ no filters
  let missingRow = props.events.map((e, idx) => (
    <tr className="Missing-list" key={idx}>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {moment(e.start).format("MM/DD/YY")}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.physicians.map((p) => p.name)}
      </td>
      <td width="10%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.event_id}
      </td>
      <td width="5%" data-bs-toggle="modal" data-bs-target={`#m${e.event_id}`}>
        {e.patient.initials.first + e.patient.initials.last}
      </td>
      <td
        width="40%"
        className=""
        data-bs-toggle="modal"
        data-bs-target={`#m${e.event_id}`}
      >
        <div className="row justify-content-center align-items-start">
          <div className="col col-md-3">
            {e.study.length !== 0 ? (
              <img width="20px" src="/images/DICOM.svg" alt="dicom icon" />
            ) : (
              <img
                width="20px"
                src="/images/DICOM-missing.svg"
                alt="dicom-missing icon"
              />
            )}
          </div>
          <div className="col col-md-3">
            <i
              className="far fa-file-pdf"
              style={{ color: e.templated ? "black" : "red" }}
            ></i>
          </div>
          <div className="col col-md-3">
            <i
              className="fas fa-ruler-vertical"
              style={{
                color:
                  e.demographics.height || e.demographics.weight
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
          <div className="col col-md-3">
            <i
              className="far fa-clipboard"
              style={{
                color:
                  e.location.name &&
                  e.initials !== ".." &&
                  e.patient.mrn &&
                  e.demographics.gender !== "Unknown" &&
                  e.demographics.age !== 0
                    ? "black"
                    : "red",
              }}
            ></i>
          </div>
        </div>
      </td>
      <td width="5%" onClick={() => props.pushCase(e.event_id)}>
        <i className={`fas fa-sync-alt `} />
      </td>
    </tr>
  ));

  return props.location.pathname === "/missing" ? (
    <div className="container-fluid table-responsive">
      <table className="table table-md table-bordered mt-4">
        <thead className="Missing-thead">
          <tr>
            <th>Date</th>
            <th>Physician</th>
            <th>Case ID</th>
            <th>Patient</th>
            <th>
              <div className="row justify-content-center align-items-start">
                <div className="col col-md-3">Dicoms</div>
                <div className="col col-md-3">Templated Img</div>
                <div className="col col-md-3">Height/Weight</div>
                <div className="col col-md-3">Case Details</div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{missingRow}</tbody>
      </table>
      {props.events.map((event) => (
        <CaseModal event={event} key={event.event_id} />
      ))}
    </div>
  ) : (
    <div className="container-fluid">
      <p className="filter-values">
        {props.phyFilter !== "" ? "Physician: " + props.phyFilter : ""}{" "}
        <span className="loc-value">
          {" "}
          {props.locFilter !== "" ? "Location: " + props.locFilter : ""}
        </span>
      </p>

      <table className="table table-sm table-bordered table-responsive">
        <thead className="SearchResults-thead">
          <tr>
            <th>Date</th>
            <th>Start Time</th>
            <th>Case ID</th>
            <th>Physician</th>
            <th>Location</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.phyFilter !== "" || props.locFilter !== "" ? filteredRow : row}
        </tbody>
      </table>
    </div>
  );
}

export default SearchResults;
