/* eslint-disable */
export const AlomEncodeType2 = (Number) => {
  if (typeof Number === "number" && Number % 1 === 0 && Number > 0) {
    var NumberArray = Number.toString().split("");
    var SumOfNumber = eval(NumberArray.join("+"));
    var SumOfLength = Number.toString().length;
    while (SumOfNumber > 9) {
      var Digit = SumOfNumber.toString().split("");
      SumOfNumber = eval(Digit.join("+"));
    }
    while (SumOfLength > 9) {
      var Digit = SumOfLength.toString().split("");
      SumOfLength = eval(Digit.join("+"));
    }
    return Number + "" + SumOfLength + "" + SumOfNumber;
  } else {
    return false;
  }
};

export const AlomDecodeType2 = (encodedUserID) => {
  // Can we convert the input into an integer?
  if (!Number.isInteger(parseInt(encodedUserID))) {
    return 0;
  }

  // Removed last 2 chars from the right, and convert back to an integer.
  const strippedUserID = parseInt(encodedUserID.slice(0, -2));

  //Is the New Value a Positive Integer?
  if (strippedUserID > 0) {
    //Does the Original Value equal to the encoded form of the New Value.
    if (AlomEncodeType2(strippedUserID) === encodedUserID) {
      return strippedUserID;
    }
  }

  return 0;
};
/* eslint-enable */
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      let value = c.substring(name.length, c.length);
      return value;
    }
  }
  return "";
};
