import React, { useEffect, useState } from "react";
import "./Calendar.css";
import moment from "moment";
import Heading from "../../components/Heading/Heading";
import FilteredTable from "../../components/FilteredTable/FilteredTable";
import CaseModal from "../../components/CaseModal/CaseModal";
import SearchResults from "../../components/SearchResults/SearchResults";
import { concat } from "lodash";
import { getEventSchedulingOverview, getQueryEvents } from "../../api/api";

function Calendar(props) {
  const [events, setEvents] = useState([]);

  //get current week cases
  useEffect(() => {
    getEventSchedulingOverview().then(
      (data) => {
        console.log(data);
        if (data && data.event !== undefined) {
          data.event.sort(function (a, b) {
            return moment(a.start) - moment(b.start);
          });
          setEvents(data.event);
        } else {
          setEvents([]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  //get prev, current, next week cases
  let getWeeklyCases = (weeklyTimeFrame) => {
    let url = `/json/event-scheduling?time_frame=${weeklyTimeFrame}`;
    getQueryEvents(url).then(
      (data) => {
        console.log(data);
        if (data && data.event !== undefined) {
          data.event.sort(function (a, b) {
            return moment(a.start) - moment(b.start);
          });
          console.log(data.event);
          setEvents(data.event);
        } else {
          setEvents([]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  //get weekly dates
  const getCurrentWeekDate = function (selected) {
    let currentDate = selected ? moment(selected) : moment();
    var monday = currentDate.add(1 - currentDate.day(), "days");
    var currentWeek = {};

    currentWeek.monday = monday.format("M/DD/YY");
    currentWeek.tuesday = monday.add(1, "days").format("M/DD/YY");
    currentWeek.wednesday = monday.add(1, "days").format("M/DD/YY");
    currentWeek.thursday = monday.add(1, "days").format("M/DD/YY");
    currentWeek.friday = monday.add(1, "days").format("M/DD/YY");
    return currentWeek;
  };

  //keep track of date
  const [day, setDay] = useState([moment()]);
  useEffect(() => {
    console.log(day);
  }, [day]);

  const today = moment();
  const nextWeek = moment(day[day.length - 1])
    .clone()
    .add(1, "week");
  const prevWeek = moment(day[day.length - 1])
    .clone()
    .subtract(1, "week");
  const selectedMonth = moment(day[day.length - 1]);
  //weekly dates
  let selectedWeek = getCurrentWeekDate(day[day.length - 1]);

  const handlePrevWeek = () => {
    setDay(day.pop());
    setDay(concat(day, prevWeek));
    let selectedWeek = getCurrentWeekDate(prevWeek);
    getWeeklyCases(
      `${moment(selectedWeek.monday).format("YYYYMMDD")}-${moment(
        selectedWeek.friday
      ).format("YYYYMMDD")}`
    );
  };

  const handleNextWeek = () => {
    setDay(day.pop());
    setDay(concat(day, nextWeek));
    let selectedWeek = getCurrentWeekDate(nextWeek);
    getWeeklyCases(
      `${moment(selectedWeek.monday).format("YYYYMMDD")}-${moment(
        selectedWeek.friday
      ).format("YYYYMMDD")}`
    );
  };
  const handleCurrWeek = () => {
    setDay(day.pop());
    setDay(concat(day, today));
    let selectedWeek = getCurrentWeekDate(today);
    getWeeklyCases(
      `${moment(selectedWeek.monday).format("YYYYMMDD")}-${moment(
        selectedWeek.friday
      ).format("YYYYMMDD")}`
    );
  };

  //get list of all physicians from api - remove dups
  const physicianList = function (events) {
    let arr = [];
    events.map((event) => {
      if (!arr.includes(event.physicians[0].name)) {
        arr.push(event.physicians[0].name);
      }
      return arr;
    });
    return arr.sort();
  };

  //get list of all hospital locations from api - remove dups
  const locationList = function (events) {
    let arr = [];
    events.map((event) => {
      if (!arr.includes(event.location.name)) {
        arr.push(event.location.name);
      }
      return arr;
    });
    return arr.sort();
  };

  //locations/physicians filter dropdown state
  const [phyFilter, setPhyFilter] = useState("");
  const [locFilter, setLocFilter] = useState("");

  //locations/physicians filter dropdown
  const filterDropdown = function (filter) {
    //filter = 0: location, filter = 1: physician
    let list = filter ? physicianList(events) : locationList(events);

    let array = [
      {
        value: "",
        option: "",
      },
    ];

    for (var i = 0; i < list.length; i++) {
      let obj = {};
      obj.value = list[i];
      obj.option = list[i];
      array.push(obj);
    }
    return array;
  };

  //'search by' state
  const [searchType, setSearchType] = useState("date");

  //'search by' dropdown
  const searchDropdown = [
    {
      value: "date",
      option: "date",
    },
    {
      value: "event id",
      option: "event id",
    },
    {
      value: "physician",
      option: "physician",
    },
  ];

  //search value
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //organize data by location row form
  const dataByLocation = function () {
    let data = [];
    let currentEvents =
      (searchValue || (startDate && endDate)) && props.isLoadedQuery
        ? props.queryEvents
        : events;

    for (var i = 0; i < currentEvents.length; i++) {
      if (!Object.keys(data).includes(currentEvents[i].location.name)) {
        data[currentEvents[i].location.name] = [];
        data[currentEvents[i].location.name].push(currentEvents[i]);
      } else {
        data[currentEvents[i].location.name].push(currentEvents[i]);
      }
    }
    return data;
  };

  const parseName = (name) => {
    name = name.split(" ");
    return name[1].substring(0, 3);
  };

  //organize data by physician row form
  const dataByPhysician = function () {
    let data = [];
    let currentEvents =
      (searchValue || (startDate && endDate)) && props.isLoadedQuery
        ? props.queryEvents
        : events;

    for (var i = 0; i < currentEvents.length; i++) {
      if (!Object.keys(data).includes(currentEvents[i].physicians[0].name)) {
        data[currentEvents[i].physicians[0].name] = [];
        data[currentEvents[i].physicians[0].name].push(currentEvents[i]);
      } else {
        data[currentEvents[i].physicians[0].name].push(currentEvents[i]);
      }
    }
    return data;
  };

  //physician/location calendar view toggle state
  //false = default location filter -> true = physician filter
  const [viewToggle, setViewToggle] = useState(false);
  let displayData = viewToggle ? dataByPhysician() : dataByLocation();

  return (
    <div className="mt-4">
      <Heading
        getCurrentWeekDate={selectedWeek}
        filterDropdown={filterDropdown}
        phyFilter={phyFilter}
        setPhyFilter={setPhyFilter}
        locFilter={locFilter}
        setLocFilter={setLocFilter}
        searchDropdown={searchDropdown}
        searchType={searchType}
        setSearchType={setSearchType}
        selectedMonth={selectedMonth}
        currentPath={props.location.pathname}
        getQuery={props.getQuery}
        queryEvents={props.queryEvents}
        queryError={props.queryError}
        isLoadedQuery={props.isLoadedQuery}
        setIsLoadedQuery={props.setIsLoadedQuery}
        setQuery={props.setQuery}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        allEvents={events}
        dateRange={dateRange}
        setDateRange={setDateRange}
        startDate={startDate}
        endDate={endDate}
        viewToggle={viewToggle}
        setViewToggle={setViewToggle}
      />
      {(searchValue !== "" || (startDate && endDate)) && props.isLoadedQuery ? (
        <SearchResults
          location={props.location}
          displayData={displayData}
          phyFilter={phyFilter}
          locFilter={locFilter}
          events={props.queryEvents}
          pushCase={props.pushCase}
          isPushCase={props.isPushCas}
        />
      ) : (
        <div className="container-fluid">
          <p className="filter-values">
            {phyFilter !== "" ? "Physician: " + phyFilter : ""}{" "}
            <span className="loc-value">
              {" "}
              {locFilter !== "" ? "Location: " + locFilter : ""}
            </span>
          </p>

          <table className="table table-sm table-bordered table-responsive mt-4">
            <thead className="Calendar-thead">
              <tr>
                <th></th>

                <th>{selectedWeek.monday} (MON)</th>
                <th>{selectedWeek.tuesday} (TUE)</th>
                <th>{selectedWeek.wednesday} (WED)</th>
                <th>{selectedWeek.thursday} (THU)</th>
                <th>{selectedWeek.friday} (FRI)</th>
              </tr>
            </thead>
            <tbody>
              {(phyFilter || locFilter) !== "" ? (
                <FilteredTable
                  viewToggle={viewToggle}
                  selectedWeek={selectedWeek}
                  phyFilter={phyFilter}
                  locFilter={locFilter}
                  events={events}
                  parseName={parseName}
                  location={props.location}
                  pushCase={props.pushCase}
                />
              ) : (
                Object.keys(displayData).map((location) => (
                  <tr key={location}>
                    <td className="align-middle" width="16%">
                      {location}
                    </td>
                    {Object.values(selectedWeek).map((day) => (
                      <td key={day}>
                        {displayData[location].map((event, idx) =>
                          moment(event.start).format("M/DD/YY") === day ? (
                            <div
                              key={`calendar-list-${event.event_id}`}
                              className="Calendar-list container"
                            >
                              <div className="row">
                                <div
                                  className="col col-sm-3"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  {event.procedure !== "Undefined"
                                    ? event.procedure
                                    : "N/A"}
                                </div>
                                <div
                                  className="col col-sm-3"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  {event.patient.initials.first +
                                    event.patient.initials.last}
                                </div>
                                <div
                                  className="col col-sm-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  {event.study.length !== 0 ? (
                                    <img
                                      width="14px"
                                      src="/images/DICOM.svg"
                                      alt="dicom icon"
                                    />
                                  ) : (
                                    <img
                                      width="14px"
                                      src="/images/DICOM-missing.svg"
                                      alt="dicom-missing icon"
                                    />
                                  )}
                                </div>
                                <div
                                  className="col col-sm-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  <i
                                    className="far fa-file-pdf"
                                    style={{
                                      color: event.templated ? "black" : "red",
                                    }}
                                  ></i>
                                </div>
                                <div
                                  className="col col-sm-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  <i
                                    className="fas fa-ruler-vertical"
                                    style={{
                                      color:
                                        event.demographics.height ||
                                        event.demographics.weight
                                          ? "black"
                                          : "red",
                                    }}
                                  ></i>
                                </div>
                                <div
                                  className="col col-sm-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#m${event.event_id}`}
                                >
                                  <i
                                    className="far fa-clipboard"
                                    style={{
                                      color:
                                        event.location.name &&
                                        event.patient.initials.first !== "" &&
                                        event.patient.initials.last !== "" &&
                                        event.patient.mrn &&
                                        event.demographics.gender !==
                                          "Unknown" &&
                                        event.demographics.age !== 0
                                          ? "black"
                                          : "red",
                                    }}
                                  ></i>
                                </div>
                                <div
                                  className="col col-sm-2"
                                  onClick={() => props.pushCase(event.event_id)}
                                >
                                  <i className={`fas fa-sync-alt `}></i>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <button
            className="btn btn-lg fas fa-angle-left mr-4"
            onClick={handlePrevWeek}
          ></button>
          <button
            className="btn btn-lg fas fa-circle"
            onClick={handleCurrWeek}
          ></button>
          <button
            className="btn btn-lg fas fa-angle-right ml-4"
            onClick={handleNextWeek}
          ></button>
        </div>
      )}

      {Object.keys(displayData).map((location) =>
        displayData[location].map((event) => (
          <CaseModal
            event={event}
            key={event.event_id}
            pushCase={props.pushCase}
          />
        ))
      )}
    </div>
  );
}

export default Calendar;
