import React, { useState } from "react";
import "./Status.css";
import Heading from "../../components/Heading/Heading";

function Status(props) {
  const [isSort, setIsSort] = useState(false);

  //toggle sort location
  const handleSort = () => {
    setIsSort(!isSort);
    props.statuses.sort(function (a, b) {
      return isSort
        ? b.location.name.localeCompare(a.location.name)
        : a.location.name.localeCompare(b.location.name);
    });
  };

  return (
    <div className="mt-4">
      <Heading currentPath={props.location.pathname} />
      <h4>Practice</h4>

      <div className="container-fluid table-responsive">
        <table className="table table-hover table-sm table-bordered">
          <thead className="Status-thead">
            <tr>
              <th>PACS Status</th>
              <th>EMR Status</th>
              <th>
                Location{" "}
                <button
                  className="btn btn-sm fas fa-sort"
                  onClick={handleSort}
                ></button>
              </th>
              <th>Provider(s)</th>
            </tr>
          </thead>
          <tbody>
            {props.statuses &&
              props.statuses.length > 1 &&
              props.statuses.map((status) => (
                <tr key={status.id}>
                  <td>
                    <img
                      className="Status-img"
                      src={`https://cdn4.iconfinder.com/data/icons/48x48-free-object-icons/48/${
                        status.location.affiliation.pacs
                          ? "Green_ball.png"
                          : "Red_ball.png"
                      }`}
                      alt="pacs-status"
                    ></img>
                  </td>
                  <td>
                    <img
                      className="Status-img"
                      src={`https://cdn4.iconfinder.com/data/icons/48x48-free-object-icons/48/${
                        status.location.affiliation.emr
                          ? "Green_ball.png"
                          : "Red_ball.png"
                      }`}
                      alt="status"
                    ></img>
                  </td>
                  <td>{status.location.name}</td>
                  <td>
                    {status.doctors.map((dr, idx) => (
                      <span key={idx}>{dr} </span>
                    ))}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Status;
