import React from "react";
import moment from "moment";

function FilteredTable(props) {
  //filtered list for location and/or physician for all events
  let filteredEvents = () => {
    if (props.events) {
      if (props.phyFilter !== "" && props.locFilter !== "") {
        return props.events.filter(
          (event) =>
            event.physicians[0].name === props.phyFilter &&
            event.location.name === props.locFilter
        );
      } else if (props.phyFilter !== "" && props.locFilter === "") {
        return props.events.filter(
          (event) => event.physicians[0].name === props.phyFilter
        );
      } else if (props.phyFilter === "" && props.locFilter !== "") {
        return props.events.filter(
          (event) => event.location.name === props.locFilter
        );
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  //organize data by location row form
  const dataByLocation = function () {
    let data = [];
    let events = filteredEvents();
    for (var i = 0; i < events.length; i++) {
      if (!Object.keys(data).includes(events[i].location.name)) {
        data[events[i].location.name] = [];
        data[events[i].location.name].push(events[i]);
      } else {
        data[events[i].location.name].push(events[i]);
      }
    }
    return data;
  };

  //organize data by physician row form
  const dataByPhysician = function () {
    let data = [];
    let events = filteredEvents();
    for (var i = 0; i < events.length; i++) {
      if (!Object.keys(data).includes(events[i].physicians[0].name)) {
        data[events[i].physicians[0].name] = [];
        data[events[i].physicians[0].name].push(events[i]);
      } else {
        data[events[i].physicians[0].name].push(events[i]);
      }
    }
    return data;
  };

  //display filtered data according to toggle view (physician/location)
  let displayData = props.viewToggle ? dataByPhysician() : dataByLocation();

  //filtered list for missing items and/or physician for all events missing biometrics/dicoms
  let filteredMissingEvents = () => {
    if (props.phyFilter !== "" && props.missingFilter !== "") {
      return props.missingEvents.filter(
        (event) =>
          event.physicians[0].name === props.phyFilter &&
          event.missingItemArray.indexOf(props.missingFilter) >= 0
      );
    } else if (props.phyFilter !== "" && props.missingFilter === "") {
      return props.missingEvents.filter(
        (event) => event.physicians[0].name === props.phyFilter
      );
    } else if (props.phyFilter === "" && props.missingFilter !== "") {
      return props.missingEvents.filter(
        (event) => event.missingItemArray.indexOf(props.missingFilter) >= 0
      );
    } else {
      return [];
    }
  };

  let row = Object.keys(displayData).map((location) => (
    <tr key={location}>
      <td className="align-middle" width="16%">
        {location}
      </td>
      {Object.values(props.selectedWeek).map((day) => (
        <td key={day}>
          {displayData[location].map((event, idx) =>
            moment(event.start).format("M/DD/YY") === day ? (
              <div key={event.event_id} className="Calendar-list container">
                <div className="row">
                  <div
                    className="col col-sm-3"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    {event.procedure !== "Undefined" ? event.procedure : "N/A"}
                  </div>
                  <div
                    className="col col-sm-3"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    {event.patient.initials.first + event.patient.initials.last}
                  </div>
                  <div
                    className="col col-sm-1"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    {event.study.length !== 0 ? (
                      <img
                        width="14px"
                        src="/images/DICOM.svg"
                        alt="dicom icon"
                      />
                    ) : (
                      <img
                        width="14px"
                        src="/images/DICOM-missing.svg"
                        alt="dicom-missing icon"
                      />
                    )}
                  </div>
                  <div
                    className="col col-sm-1"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    <i
                      className="far fa-file-pdf"
                      style={{ color: event.templated ? "black" : "red" }}
                    ></i>
                  </div>
                  <div
                    className="col col-sm-1"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    <i
                      className="fas fa-ruler-vertical"
                      style={{
                        color:
                          event.demographics.height || event.demographics.weight
                            ? "black"
                            : "red",
                      }}
                    ></i>
                  </div>
                  <div
                    className="col col-sm-1"
                    data-bs-toggle="modal"
                    data-bs-target={`#m${event.event_id}`}
                  >
                    <i
                      className="far fa-clipboard"
                      style={{
                        color:
                          event.location.name &&
                          event.patient.initials.first !== "" &&
                          event.patient.initials.last !== "" &&
                          event.patient.mrn &&
                          event.demographics.gender !== "Unknown" &&
                          event.demographics.age !== 0
                            ? "black"
                            : "red",
                      }}
                    ></i>
                  </div>
                  <div
                    className="col col-sm-2"
                    onClick={() => props.pushCase(event.event_id)}
                  >
                    <i className={`fas fa-sync-alt `}></i>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </td>
      ))}
    </tr>
  ));

  //filtered events for missing items page
  let missingRow =
    props.missingEvents &&
    filteredMissingEvents().map((m, idx) => (
      <tr className="Missing-list" key={idx}>
        <td
          width="2%"
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          {idx + 1}
        </td>
        <td
          width="10%"
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          {moment(m.start).format("MM/DD/YY")}
        </td>
        <td
          width="10%"
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          {m.physicians.map((p) => p.name)}
        </td>
        <td
          width="10%"
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          {m.event_id}
        </td>
        <td
          width="5%"
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          {m.patient.initials.first + m.patient.initials.last}
        </td>
        <td
          width="40%"
          className=""
          data-bs-toggle="modal"
          data-bs-target={`#m${m.event_id}`}
        >
          <div className="row justify-content-center align-items-start">
            <div className="col col-md-3">
              {m.study.length !== 0 ? (
                <img width="20px" src="/images/DICOM.svg" alt="dicom icon" />
              ) : (
                <img
                  width="20px"
                  src="/images/DICOM-missing.svg"
                  alt="dicom-missing icon"
                />
              )}
            </div>
            <div className="col col-md-3">
              <i
                className="far fa-file-pdf"
                style={{ color: m.templated ? "black" : "red" }}
              ></i>
            </div>
            <div className="col col-md-3">
              <i
                className="fas fa-ruler-vertical"
                style={{
                  color:
                    m.demographics.height || m.demographics.weight
                      ? "black"
                      : "red",
                }}
              ></i>
            </div>
            <div className="col col-md-3">
              <i
                className="far fa-clipboard"
                style={{
                  color:
                    m.location.name &&
                    m.initials !== ".." &&
                    m.patient.mrn &&
                    m.demographics.gender !== "Unknown" &&
                    m.demographics.age !== 0
                      ? "black"
                      : "red",
                }}
              ></i>
            </div>
          </div>
        </td>
        <td width="5%" onClick={() => props.pushCase(m.event_id)}>
          <i className={`fas fa-sync-alt `} />
        </td>
      </tr>
    ));

  return props.location.pathname === "/" ? row : missingRow;
}

export default FilteredTable;
