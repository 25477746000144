import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

import { Context as AuthContext } from "../../context/AuthContext";
import { Context as GlobalContext } from "../../context/GlobalContext";
import { jwtTokenURI, loginStatusURI } from "../../dataAccess/apiEndpoints";

const NavBar = (props) => {
  const { state: authState, loginStatus, getToken } = useContext(AuthContext);
  const { state: globalState } = useContext(GlobalContext);

  // check login status on app initialization
  useEffect(() => {
    console.log("global", globalState.config.ABS_BASE_URL);
    if (globalState.config.ABS_BASE_URL) {
      const url = `${globalState.config.ABS_BASE_URL}${loginStatusURI}`;
      // check login Status
      loginStatus(url);
    }
  }, [globalState.config.ABS_BASE_URL]);

  // if isLoggedIn changes, ping the jwt API
  useEffect(() => {
    if (
      authState.isLoggedIn === true &&
      authState.jwt === null &&
      globalState.config.ABS_BASE_URL
    ) {
      const url = `${globalState.config.ABS_BASE_URL}${jwtTokenURI}`;
      getToken(url);
    }
  }, [authState.isLoggedIn, globalState.config.ABS_BASE_URL]);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <img
          className="Navbar-logo"
          src="/images/docspera_logo_1.svg"
          alt="docspera logo"
        />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="selected"
              >
                ACM Dashboard
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/status" className="nav-link" activeClassName="selected">Integration Status</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink to="/locnpro" className="nav-link">Locations &amp; Providers </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/missing"
                className="nav-link position-relative"
                activeClassName="selected"
              >
                Missing
                <div className="notification">{props.missingItems.length}</div>
              </NavLink>
            </li>
          </ul>
          {/* <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/login" className="nav-link" activeClassName="selected">Login</NavLink>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
