import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import moment from "moment";
import "./App.css";
import NavBar from "../../components/NavBar/NavBar";
import Calendar from "../Calendar/Calendar";
import Status from "../Status/Status";
import Info from "../Info/Info";
import Missing from "../Missing/Missing";
import { Provider as AuthProvider } from "../../context/AuthContext";
import { Provider as GlobaleProvider } from "../../context/GlobalContext";
import { getQueryEvents, putEvent } from "../../api/api";

function App() {
  //for missing tab: get 3 weeks of cases starting from today's date
  const [missing, setMissing] = useState([]);
  useEffect(() => {
    const baseURL = "/json/event-scheduling?time_frame=";
    let today = moment().format("YYYYMMDD");
    let three_wks = moment().add(3, "week").format("YYYYMMDD");
    let url = baseURL + today + "-" + three_wks;
    getQueryEvents(url).then(
      (data) => {
        console.log(data);
        if (data && data.event !== undefined) {
          data.event.sort(function (a, b) {
            return moment(a.start) - moment(b.start);
          });
          console.log(data.event);
          setMissing(data.event);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  //ajax call for all statuses
  // const getStatuses = () => {
  //   fetch('statuses.json', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(
  //       (data) => {
  //         console.log(data.statuses);
  //         setIsLoadedStatuses(true);
  //         setStatuses(data.statuses);
  //       },
  //       (error) => {
  //         console.log(error);
  //         setIsLoadedStatuses(true);
  //         setStatusesError(error);
  //       })
  // }
  // useEffect(() => {
  //   getStatuses()
  // }, [])

  //query cases
  const [queryError, setQueryError] = useState(null);
  const [isLoadedQuery, setIsLoadedQuery] = useState(false);
  const [queryEvents, setQuery] = useState([]);
  let getQuery = (searchValue, searchType) => {
    console.log(searchValue);
    const baseURL = "/json/event-scheduling?";
    let searchURL = "";
    switch (searchType) {
      case "date":
        searchURL = `${baseURL}time_frame=${searchValue}`;
        break;
      case "event id":
        searchURL = `${baseURL}event_id=${searchValue}`;
        break;
      default:
        break;
    }
    console.log(searchURL);
    getQueryEvents(searchURL).then(
      (data) => {
        console.log(data);
        if (data && data.event !== undefined) {
          data.event.sort(function (a, b) {
            return moment(a.start) - moment(b.start);
          });
          console.log(data.event);
          setIsLoadedQuery(true);
          setQuery(data.event);
        }
      },
      (error) => {
        console.log(error);
        setIsLoadedQuery(true);
        setQueryError(error);
      }
    );
  };

  //list of cases with missing items
  let missingItems = function () {
    let arr = [];
    let three_wks = moment().add(3, "week").endOf("day");
    missing &&
      missing.length > 0 &&
      missing.forEach((m) => {
        if (
          moment(m.start) >= moment().startOf("day") &&
          moment(m.start) <= three_wks
        ) {
          m.missingItemArray = [];
          if (m.templated === 0) {
            //missing templated img
            m.missingItemArray.push("template");
          }
          if (m.study.length === 0) {
            //missing dicoms
            m.missingItemArray.push("dicom");
          }
          if (m.demographics.height === "" || m.demographics.weight === "") {
            //missing height/weight
            m.missingItemArray.push("ht-wt");
          }
          if (
            m.location.name ||
            m.initials === ".." ||
            m.patient.mrn ||
            m.demographics.gender === "Unknown" ||
            m.demographics.age === 0
          ) {
            //missing case details
            m.missingItemArray.push("case-details");
          }
          arr.push(m);
        }
        return arr;
      });
    return arr.sort((a, b) => {
      return moment(a.start) - moment(b.start);
    });
  };

  const pushCase = (event_id) => {
    const pushCaseURL = `/grpc/push/${event_id}`;
    putEvent(pushCaseURL).then(
      (data) => {
        console.log(data);
        console.log("success");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <AuthProvider>
      <GlobaleProvider>
        <div className="App">
          <NavBar missingItems={missingItems()} />

          <Switch>
            <Route
              exact
              path="/missing"
              render={(props) => (
                <Missing
                  {...props}
                  missingItems={missingItems()}
                  queryEvents={queryEvents}
                  isLoadedQuery={isLoadedQuery}
                  setIsLoadedQuery={setIsLoadedQuery}
                  setQuery={setQuery}
                  pushCase={pushCase}
                  isPushCase={false}
                />
              )}
            />
            <Route path="/locnpro" component={Info} />
            <Route
              path="/status"
              render={(props) => (
                <Status
                  {...props}
                  statuses={[]}
                  fetchError={null}
                  isLoaded={false}
                />
              )}
            />

            <Route
              exact
              path="/"
              render={(props) => (
                <Calendar
                  {...props}
                  getQuery={getQuery}
                  queryEvents={queryEvents}
                  queryError={queryError}
                  isLoadedQuery={isLoadedQuery}
                  setIsLoadedQuery={setIsLoadedQuery}
                  setQuery={setQuery}
                  pushCase={pushCase}
                  isPushCase={false}
                />
              )}
            />
          </Switch>
        </div>
      </GlobaleProvider>
    </AuthProvider>
  );
}

export default App;
